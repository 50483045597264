import _ from 'lodash'
import { SelectFullOptionTP } from 'main/common/components/form-fields/select/inner/types/SelectFullOptionTP'
import { SelectCP } from 'main/common/components/form-fields/select/SelectCP'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import { IPersonWithSessionsAvailableRequestDTO } from 'main/modules/scheduler/services/scheduler/dtos/request/IPersonWithSessionsAvailableRequestDTO'
import { IPersonWithSessionsAvailableResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IPersonWithSessionsAvailableResponseDTO'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import React, { useEffect, useState } from 'react'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'

interface ISelectCustomerWithSessionsCPProps {
    clientSessions: (sessions: ISessionResponseDTO[]) => void
    attendantCode?: number
    onSelectCustomer: (customer: INameAndCodeResponseDTO) => void
    initialValue?: INameAndCodeResponseDTO
    disabled?: boolean
}

/**
 * Select de Cliente com as sessoes que ele possui.
 */
export function SelectCustomerWithSessionsCP(props: ISelectCustomerWithSessionsCPProps): JSX.Element {

    const [selectedClient, setSelectedClient] = useState<string>()
    const [clientOptions, setClientOptions] = useState<SelectFullOptionTP[]>([])
    const [searchString, setSearchString] = useState<string>()

    const clientsRequest = useRequest<IPersonWithSessionsAvailableResponseDTO[]>()

    useEffect(() => setSelectedClient(props.initialValue?.name), [props.initialValue])
    useEffect(getClientList, [searchString])
    useEffect(onClientsRequestChange, [clientsRequest.isAwaiting])

    function getClientList(): void {

        const dto: IPersonWithSessionsAvailableRequestDTO = {
            searchString,
            professionalCode: props.attendantCode
        }

        clientsRequest.runRequest(PersonRequests.getClients(dto))
    }

    function onClientsRequestChange(): void {
        if (!RequestUtils.isValidRequestReturn(clientsRequest, 'Erro ao buscar lista de clientes'))
            return
        
        console.log("Pessoas recebidas ",clientsRequest.responseData!)
        let mp:any = {};
        clientsRequest.responseData!.forEach((i:any)=>mp[i.code]=i);
        const opt = Object.values(mp).map((prof: any) => (
            { value: prof.code, key: `${prof.code}`, label: prof.name }
        ))
        setClientOptions(opt)
    }

    function onSelectClient(clientCode: number): void {
        const _client = _.find(clientOptions, client => client.value === clientCode)

        if (_client) {
            setSelectedClient(_client.label)
            props.onSelectCustomer({
                code: clientCode,
                name: _client.label
            })
        }

        const clientAppointments = _.find(clientsRequest.responseData, client => client.code === clientCode)

        if (clientAppointments)
            props.clientSessions(clientAppointments.sessions)
    }

    return (
        <SelectCP
            label={'Cliente'}
            required={true}
            value={selectedClient}
            onSearch={_.debounce(setSearchString, 500)}
            options={clientOptions}
            notFoundContent={'Nenhum cliente para agendar os serviços'}
            onChange={onSelectClient}
            loading={clientsRequest.isAwaiting}
            disabled={props.disabled}
            allowClear={true}
        />
    )
}


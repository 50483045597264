import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { BlockIntervalModalCP } from 'main/modules/scheduler/components/block-interval-modal/BlockIntervalModalCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import SchedulerRequests from 'main/modules/scheduler/services/scheduler/SchedulerRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { ScheduleSessionsFormModel } from 'main/modules/scheduler/components/drawer-schedule-session/inner/ScheduleSessionsFormModel'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { IScheduleSlotInitialData } from 'main/modules/scheduler/components/drawer-schedule-session/inner/IScheduleSlotInitialData'
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP'
import { faBan, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import styled from 'styled-components'
import { StyledComponentProps } from 'main/config/theme/Theme'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { SelectCustomerAndTreatmentCP } from 'main/modules/scheduler/components/select-customer-and-treament/SelectCustomerAndTreatmentCP'
import { TextAreaCP } from 'main/common/components/form-fields/text-area/TextAreaCP'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { SwitchRecurrenceAppointmentCP } from 'main/modules/scheduler/components/switch-recurrence-appointment/SwitchRecurrenceAppointmentCP'
import { AutocompleteUserCP } from 'main/modules/user/components/autocomplete-user/AutocompleteUserCP'
import { SaleStatusEnum } from 'main/modules/sale/services/sale/enums/SaleStatusEnum'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { AlertOverlapSessionCP } from 'main/modules/scheduler/components/alerts/alert-overlap-session/AlertOverlapSessionCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ScheduleRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/ScheduleRequestDTO'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import axios from 'axios'
import { PopConfirmCP } from 'main/common/components/pop-confirm/PopConfirmCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'

interface IDrawerScheduleSessionCPProps {
    visible: boolean
    onClose: (dataChanged?: boolean) => void
    scheduleSessionInitialData: IScheduleSlotInitialData
    showBlockIntervalButton: boolean
}

/**
 * COMPONENTE Drawer para agendar sessoes.
 */
export function DrawerScheduleSessionCP(props: IDrawerScheduleSessionCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<ScheduleSessionsFormModel>(new ScheduleSessionsFormModel())
    const formStateManager = useFormStateManager(formValidator)

    const [checkOverlapUpdate, setCheckOverlapUpdate] = useState<number>()
    const [integracaoZap,setIntegracaoZap] = useState<boolean>(false);
    const [selectSessions, setSelectedSessions] = useState<ISessionResponseDTO[]>([])
    const [isBlockIntervalModalVisible, setIsBlockIntervalModalVisible] = useState<boolean>(false)
    const [podeBloquear, setPodeBloquear] = useState<boolean>(false);
    useEffect(init, [props.visible, props.scheduleSessionInitialData]);
    const createSessionRequest = useRequest<ISessionResponseDTO>()
    useEffect(onCreateAppointmentRequestChange, [createSessionRequest.isAwaiting])
    

    async function obterDadosPortaWhatsApp(slug:any,idUnidade:any){
       
        console.log("Obtendo dados de integracao ", slug,idUnidade);
        const data = JSON.stringify({
            "nome_schema": slug,
            "id_empresa": idUnidade
          });
        //@ts-ignore
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://whats.lumiacrm.com.br/controleWhats/buscarWhatsControle',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          //@ts-ignore
          const resultado = await axios.request(config);
           console.log("O resultado da integração zap ", resultado['data']);
          if(resultado['data'] && resultado.data.length && resultado.data[0]){
            if(resultado?.data[0]?.id_empresa){
                setIntegracaoZap(true);
                console.log("ZAPTRUE",integracaoZap);
            }

          }else{
              setIntegracaoZap(false);
              console.log("ZAPFALSE");
          }
    }

   

    /**
     * Inicializa dados para funcionameto.
     */
    function init(): void {
        if(AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.BLOQUEAR_HORARIOS) || AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_ADMIN) ){
            setPodeBloquear(true);
        }else{
            setPodeBloquear(false);
        }
        setCheckOverlapUpdate(undefined)
        setFormValidator(new ScheduleSessionsFormModel())
        if (!props.visible)
            return

        setFormValidator(new ScheduleSessionsFormModel({
            userCode: props.scheduleSessionInitialData.userProfessional?.code,
            customerCode: props.scheduleSessionInitialData.customer?.code,
        }))

        obterDadosPortaWhatsApp(AppStateUtils.getDomainData()?.schema.slug,AppStateUtils.getCurrentFranchise()?.code);
    }

    /**
     * Agenda sessoes.
     */
    async function scheduleSessions(notificar:boolean = true): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return NotificationHelper.warning('Atenção!', 'Confira todos os campos antes de finalizar o cadastro')

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        if (formValues.isRecurrent && !formValues.recurrencePeriod) {
            NotificationHelper.error('Ops!', 'Informe qual a periodicidade dos agendamentos recorrentes')
            return
        }

        const dto: ScheduleRequestDTO = {
            professionalCode: +formValues.userCode,
            clientCode: formValues.customerCode,
            sessions: formValues.sessions,
            comment: formValues.comment,
            recurrentSchedule: formValues.isRecurrent ? formValues.recurrencePeriod : undefined,
            shouldFitSchedule: formValues.shouldShrink,
            suprimirNotificacao:!notificar
        }
        createSessionRequest.runRequest(SchedulerRequests.createAppointment(dto))
    }

    /**
     * Retorno da API ao agendar sessoes.
     */
    function onCreateAppointmentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(createSessionRequest, 'Erro ao salvar agendamento'))
            return

        NotificationHelper.success('Yes', 'Horário agendado!')
        props.onClose(true)
    }

    function enviar(){//com notificação zap
        scheduleSessions();
    }

    function naoEnviar(){// sem notificacao zap
        scheduleSessions(false);
    }

    function outraAcao(){

    }

    function verificacoes(){
        let mensagem = ArrayUtils.isEmpty(selectSessions.filter((session) => [SaleStatusEnum.CANCELLED, SaleStatusEnum.DEFAULTING, SaleStatusEnum.PENDING].includes(session.saleStatus)))
        ? undefined
        : `Existe uma ou mais sessões com venda em Pagamento Pendente, Inadimplente ou Cancelada, deseja mesmo prosseguir?`

        if(integracaoZap)

        return mensagem;
    }

    return (
        <>
        
            <DrawerCP
                title={'Agendar Sessões'}
                visible={props.visible}
                onClose={props.onClose}
                width={700}
                footer={
                    <>
                        <ConditionalRenderCP shouldRender={integracaoZap}>
                            <PopConfirmCP  title='Integração com WhatsApp, Sua clínica possui integração com whatsapp, deseja notificar o cliente do agendamento?'  placement='left' key={'keyt'} okText='Notificar' cancelText='Não' onCancel={naoEnviar} onConfirm={enviar}>
                                <ButtonCP
                                    onClick={outraAcao}
                                    type={'primary'}
                                    loading={createSessionRequest.isAwaiting}
                                                                        
                                >
                                    Agendar
                                </ButtonCP>
                            </PopConfirmCP>
                        </ConditionalRenderCP>

                        <ConditionalRenderCP shouldRender={!integracaoZap}>
                            
                                <ButtonCP
                                    onClick={scheduleSessions}
                                    type={'primary'}
                                    loading={createSessionRequest.isAwaiting}
                                    confirmMsg={
                                    verificacoes()
                                    }
                                    
                                >
                                    Agendar
                                </ButtonCP>
                        </ConditionalRenderCP>

                        
                    </>
                }
            >

                

                <StyledDateWrapperSCP>
                    <DateTextSCP>
                        <StyledIconSCP><FontAwsomeIconCP type={faCalendarAlt}/></StyledIconSCP>
                        <StyledPrimaryTextSCP>
                            { DateUtils.getFormatted(props.scheduleSessionInitialData.initialTime.toDate(), DateFormatEnum.DATE_WRITTEN_IN_FULL) }
                        </StyledPrimaryTextSCP>
                    </DateTextSCP>

                    {
                        props.showBlockIntervalButton && podeBloquear && props.scheduleSessionInitialData.userProfessional &&
                        <ButtonCP
                            onClick={() => setIsBlockIntervalModalVisible(true)}
                            tooltip={'Bloquear este horário'}
                            type={'primary'}
                        >
                            <FontAwsomeIconCP type={faBan}/>
                        </ButtonCP>
                    }
                </StyledDateWrapperSCP>

                <SectionInfoWrapperSCP>
                    <RowCP>
                        <ColumnCP size={12}>
                            <AutocompleteUserCP
                                label={'Profissional'}
                                tempo={props.scheduleSessionInitialData.initialTime.toDate()}
                                formStateManager={formStateManager}
                                fieldName={'userCode'}
                                initialOptions={props.scheduleSessionInitialData.userProfessional ? [props.scheduleSessionInitialData.userProfessional] : undefined}
                                disabled={!!props.scheduleSessionInitialData.userProfessional}
                                onlyWithWorkSchedule={true}
                                required={true}
                            />
                        </ColumnCP>
                    </RowCP>

                    <SelectCustomerAndTreatmentCP
                        onSelectCustomer={(customer) => formStateManager.changeFieldValue('customerCode', customer.code)}
                        onChangeSessionsToSchedule={(sessions) => {
                            formStateManager.changeFieldValue('sessions', sessions)
                            if (!ArrayUtils.isEmpty(sessions))
                                setCheckOverlapUpdate(TableUtils.getReloadNumber())
                        }}
                        scheduleSessionInitialData={props.scheduleSessionInitialData}
                        onChangeSessions={setSelectedSessions}
                        renderComponentsConfig={{
                            renderSelectsAs: 'column',
                            showResourceColumnOnTable: true,
                            showTimeColumnOnTable: true,
                            showDurationColumnOnTable: false,
                            showActionsTable: true,
                        }}
                    />
                </SectionInfoWrapperSCP>

                <SectionInfoWrapperSCP>
                    <SwitchRecurrenceAppointmentCP formStateManager={formStateManager}/>
                </SectionInfoWrapperSCP>

                <SectionInfoWrapperSCP>
                    <TextAreaCP
                        label={'Observação'}
                        formStateManager={formStateManager}
                        fieldName={'comment'}
                        value={formStateManager.getFieldValue('comment')}
                    />
                </SectionInfoWrapperSCP>

                <AlertOverlapSessionCP
                    formStateManager={formStateManager}
                    shouldUpdate={checkOverlapUpdate}
                />

            </DrawerCP>

            {
                props.scheduleSessionInitialData.userProfessional &&
                <BlockIntervalModalCP
                    visible={isBlockIntervalModalVisible}
                    userProfessionalCode={props.scheduleSessionInitialData.userProfessional.code}
                    initialTime={props.scheduleSessionInitialData.initialTime}
                    onClose={() => setIsBlockIntervalModalVisible(false)}
                    onActionDone={() => {
                        setIsBlockIntervalModalVisible(false)
                        props.onClose()
                    }}
                />
            }
        </>
    )

}

const DateTextSCP = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const StyledDateWrapperSCP = styled.div`
    border: 2px dashed ${(props: StyledComponentProps) => props.theme.gray};
    border-radius: 8px;
    padding: .2rem;
    display: flex;
    align-items: center;
    justify-content:space-between;
`

const StyledIconSCP = styled.div`
    padding: 10px 10px 5px 10px;
`

const StyledPrimaryTextSCP = styled.div`
    && {
        color: ${(props: StyledComponentProps) => props.theme.darkGray};
        font-family: ${(props: StyledComponentProps) => props.theme.textFontFamily};
        font-weight: 500;
        font-size: ${(props: StyledComponentProps) => props.theme.fontSizes.small};
    }
`

const SectionInfoWrapperSCP = styled.div`
    margin: 20px 0;
`


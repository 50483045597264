import { TableCPOLD2 } from 'common/old-refactory/table/TableCPOLD2'
import { ISessionResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/ISessionResponseDTO'
import React, { useEffect, useState } from 'react'
import { AppointmentDetailsTableUtils } from 'main/modules/scheduler/components/appointments-details-table/inner/AppointmentDetailsTableUtils'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import SchedulerRequests from 'main/modules/scheduler/services/scheduler/SchedulerRequests'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'
import styled from 'styled-components'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { SystemConfig } from 'main/config/SystemConfig'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { DrawerCP } from 'main/common/components/drawer/DrawerCP'
import { TableCP } from 'main/common/components/table/TableCP'
import { UtilGeral } from 'main/modules/people/utils/UtilGeral'
import { TooltipCP } from 'main/common/components/tooltip/TooltipCP'
import { Icon } from 'antd'

interface IAppointmentsDetailsTableCPProps {
    sessions: ISessionResponseDTO[]
    onAnswerSession: (session: ISessionResponseDTO) => void
    onCancelSession: (rowIndex: number) => void
    onSelectSale: (saleCode: number) => void
    hideAnswerAction?: boolean
}


/**
 * COMPONENTE de tabela que exibe detalhes de um agendamento, em geral, ou das sessões que irão ser consumidas em um agendamento
 */
export function AppointmentsDetailsTableCP(props: IAppointmentsDetailsTableCPProps): JSX.Element {

    const [sessionToCancelRowIndex, setSessionToCancelRowIndex] = useState<number>()
    const [carregando, setCarregando] = useState<boolean>(false);
    const [dadosNotificacao, setDadosNotificacao] = useState<any>(null);
    const cancelSessionRequest = useRequest<void>('none')

    const colunasExecucao:any = [
        {
          title: 'MSG',
          dataIndex: 'mgx',
          key: 'mts_whatsappmessage',
          render:(txt:any,dados:any)=>{
            return (
                <Centralizado>
                    <TooltipCP text={dados.mts_whatsappmessage}> 
                        <Icon 
                        type={'question-circle'}
                        className='ic-acao'
                        // title='Clique para detalhes de notificação.'
                        />
                    </TooltipCP>
                    <TooltipCP text={dados.mts_smsmessage}> 
                        <Icon 
                        type={'info-circle'}
                        className='ic-acao'
                        // title='Clique para detalhes de notificação.'
                        />
                    </TooltipCP>
                    <TooltipCP text={dados.mts_emailmessage}> 
                        <Icon 
                        type={'close-circle'}
                        className='ic-acao'
                        // title='Clique para detalhes de notificação.'
                        />
                    </TooltipCP>
                </Centralizado>
                )
          }
        },
        {
          title: 'Evento',
          dataIndex: 'mts_event',
          key: 'mts_event',
          render:(txt:any,dados:any)=><Pequeno>{UtilGeral.eventoMarketing(dados.mts_event)}</Pequeno>
        },
        {
          title: 'Razão',
          dataIndex: 'mts_statusrreason',
          key: 'mts_statusrreason',
          render:(txt:any,dados:any)=><Pequeno>{dados.mts_statusrreason}</Pequeno>
        },
        {
          title: 'Criação',
          dataIndex: 'mts_createdate',
          key: 'mts_createdate',
          render:(txt:any,dados:any)=><Pequeno>{UtilGeral.timestampDeISO(dados.mts_createdate)}</Pequeno>
        },
        {
          title: 'Agendado para',
          dataIndex: 'mts_scheduledate',
          key: 'mts_scheduledate',
          render:(txt:any,dados:any)=><Pequeno>{UtilGeral.timestampDeISO(dados.mts_scheduledate)}</Pequeno>
        },
        {
            title: 'STATUS',
            dataIndex: 'mts_status',
            key: 'mts_status',
            render:(txt:any,dados:any)=><Pequeno>{UtilGeral.statusMarketing(dados.mts_status)}</Pequeno>
        }
    ];
    

    console.log(dadosNotificacao, colunasExecucao);

    useEffect(onCancelSessionRequestChange, [cancelSessionRequest.isAwaiting])

    /**
     * Remover sessao.
     */
    function cancelSession(code: number, rowIndex: number): void {
        setSessionToCancelRowIndex(rowIndex)
        cancelSessionRequest.runRequest(SchedulerRequests.cancelAppointments([code]))
    }

    async function verMarketing(session: any){
        console.log("Vendo marketing ", session.code);
        setDadosNotificacao(null);
        setCarregando(true);
         const resultado:any = await RequestHelper.runRequest({
                    url: `${SystemConfig.getInstance().apiBaseUrl}/dashboard/notificacoes`,
                    method: HttpMethodEnum.POST,
                    params: {
                        eventPK:session.code,
                        codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                        schema:AppStateUtils.getDomainSlug()
                    }
                   
                }).finally(()=>setCarregando(false));
        console.log("Resultado ", resultado?.data?.data);
        if(resultado?.data?.data?.length){
            setDadosNotificacao(resultado?.data?.data);
            return;
        }
        NotificationHelper.error('Sem notificações','Não há notificações atreladas nesta sessão',3,'topRight');
    }

    /**
     *  Retorno da remocao de sessao.
     */
    function onCancelSessionRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(cancelSessionRequest, 'Não foi cancelar o agendamento', true))
            return

        NotificationHelper.success('Pronto!', 'Sessão cancelada com sucesso')
        props.onCancelSession(sessionToCancelRowIndex!)
    }

    return (
        <WrapperSCP>
            <DrawerCP title={'Detalhe de Notificações'} width={800} visible={!!dadosNotificacao}>
                <Legenda>
                    <div>
                        <Icon 
                            type={'question-circle'}
                            className='ic-acao'
                            // title='Clique para detalhes de notificação.'
                            /> Mensagem de WhatsApp
                    </div>
                    <div>
                        <Icon 
                            type={'info-circle'}
                            className='ic-acao'
                            // title='Clique para detalhes de notificação.'
                        /> Mensagem de SMS
                    </div>
                    <div>
                        <Icon 
                            type={'close-circle'}
                            className='ic-acao'
                           
                            // title='Clique para detalhes de notificação.'
                        /> Mensagem de Email
                    </div>
                </Legenda>
                    
                <TableCP
                columns={colunasExecucao}
                data={dadosNotificacao ?? []}
                
                />
            </DrawerCP>
            
            <TableCPOLD2
                
                data={props.sessions}
                loading={cancelSessionRequest.isAwaiting || carregando}
                columns={AppointmentDetailsTableUtils.getDetailsTableColumnsConfig(
                    props.onAnswerSession,
                    cancelSession,
                    props.onSelectSale,
                    props.hideAnswerAction,
                    verMarketing
                )}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  .ant-table {
    overflow: scroll;
  }
`

const Pequeno = styled.div`
    font-weight: 645;
    letter-spacing: -0.048em;
    font-size:9px;
    text-transform:uppercase;
    

`

const Centralizado = styled.div`
    display:flex;
    justify-content:center;
    align-itens:center;
`

const Legenda = styled.div`
    display:flex;
    flex-direction:column;
    gap:5px;
    font-weight: 900;
    padding: 7px;
    border: solid 2px gray;
    border-radius: 12px;
    margin-bottom: 11px;
`

import { NotificationHelper } from "main/common/helpers/NotificationHelper";

export class UtilGeral {
    static VALIDAR_OBJETO(ob:any, campos:any,nomeAmigavel:any = null){
        for (let i = 0; i<campos.length; i++){
          const cp = campos[i];
          if(!ob[cp] && ob[cp]!=0){
            let amig = null;
            if(nomeAmigavel && nomeAmigavel[i])amig = nomeAmigavel[i];
            const nome = amig || cp;
            NotificationHelper.error('Atenção',`FAVOR ${UtilGeral.TEXTO(nome)}`);
            return false;
          }
        }
        return true;
      }
    
      static TEXTO = (v:any)=>{
        if(!v || v=='null')return '';
        return UtilGeral.REMOVER_CORINGA(UtilGeral.removerAcentos((v+'').toUpperCase()).trim(),[';',':','\\','n']);
      }

      static REMOVER_CORINGA = (v:any,vet:any)=>{
        return (v+'').replace(/\n/g,'').split('').filter(i=>!vet.includes[i]).join('');
      }

      static DEVOLVER_SO_NUMEROS(s:any){
        return (s+'').split('').filter((i:any)=>'0123456789'.includes(i)).join('');
      }



    static objetoVirgulaPorPonto(obj:any) {
      const newObj = {};
  
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              let value = obj[key];
  
              // Verifica se o valor é uma string e representa um float
              if (typeof value === 'string' && !isNaN(parseFloat(value.replace(/,/g, '.'))) && value.includes(',')) {
                  // Substitui a vírgula por ponto
                  value = value.replace(/,/g, '.');
  
                  // Converte a string em número
                  const floatValue = parseFloat(value);
                  if (!isNaN(floatValue)) {
                      value = floatValue;
                  }
              } else if (typeof value === 'object' && value !== null) {
                  // Verifica recursivamente em caso de objeto aninhado
                  value = UtilGeral.objetoVirgulaPorPonto(value);
              }
  
              newObj[key] = value;
          }
      }
  
      return newObj;
  }
 
  

    static objetoPontoPorVirgula(obj:any) {
      const newObj = {};
  
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              let value = obj[key];
  
              // Verifica se o valor é um número float ou uma string que representa um float
              if (typeof value === 'number' || (typeof value === 'string' && !isNaN(parseFloat(value)) && value.includes('.') && !value.includes(':') && !value.includes('-') && !value.includes('0Z'))) {
                  // Substitui o ponto por vírgula
                  if (typeof value === 'string') {
                      value = value.replace(/\./g, ',');
                  } else if (typeof value === 'number') {
                      value = value.toString().replace(/\./g, ',');
                  }
              } else if (typeof value === 'object' && value !== null) {
                  // Verifica recursivamente em caso de objeto aninhado
                  value = UtilGeral.objetoPontoPorVirgula(value);
              }
  
              newObj[key] = value;
          }
      }
  
      return newObj;
  }
  

    static configPadraoDeConta(){
      return {"integrationKey": "null", "rulesByPaymentMethods": [{"paymentMethod": "BANK_BILLET", "maxDaysToExpire": 10, "minDaysToExpire": 0, "daysUntilPayment": 5, "enableInstallments": true}, {"paymentMethod": "CREDIT_CARD_RECURRENT", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 30, "enableInstallments": true}, {"paymentMethod": "CREDIT_CARD", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 30, "enableInstallments": true}, {"paymentMethod": "PIX", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": false}, {"paymentMethod": "MONEY", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": false}, {"paymentMethod": "PAYMENT_BOOK", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": true}, {"paymentMethod": "DEPOSIT", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": false}, {"paymentMethod": "CHECK", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": false}, {"paymentMethod": "DEBIT", "maxDaysToExpire": 0, "minDaysToExpire": 0, "daysUntilPayment": 1, "enableInstallments": false}]}
    }

  

    static  dateParaStringFormatada(data:any) {
      console.log("Formatando data ", data);
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0'); // Meses são indexados a partir de 0
      const ano = data.getFullYear();
      return `${dia}/${mes}/${ano}`;
    }

    static formatarDataISO(dataISO:any) {
      const data = new Date(dataISO);
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0'); // Meses são indexados a partir de 0
      const ano = data.getFullYear();
      return `${dia}/${mes}/${ano}`;
    }

    static formatarDataGeral(dataInput: string): string {
      const data = new Date(dataInput);
    
      // Verificar se a data é inválida
      if (isNaN(data.getTime())) {
        throw new Error('Formato de data inválido');
      }
    
      // Garantindo formato consistente
      const dia = String(data.getUTCDate()).padStart(2, '0');
      const mes = String(data.getUTCMonth() + 1).padStart(2, '0'); // Meses são indexados a partir de 0
      const ano = data.getUTCFullYear();
    
      return `${dia}/${mes}/${ano}`;
    }

    static eventoMarketing(status:any){
      const o:any = {
        'SESSION_ALERT':'SESSÃO MARCADA',
        'SESSION_SCHEDULE':'SESSÃO AGENDADA',
        'PERSON_BIRTHDAY':'MSG ANIVERSÁRIO',
        'EVALUATION_WITH_FREE_SALE':'AVALIÇÃO COM VENDA GRÁTIS',
        'EVALUATION_ALERT':'ALERTA DE AVALIAÇÃO',
        'EVALUATION_WITHOUT_SALE':'AVALIAÇÃO SEM VENDA',
        'EVALUATION_WITH_SALE':'AVALIAÇÃO COM VENDA',
        'EVALUATION_SCHEDULE':'AVALIAÇÃO AGENDADA',
        'EVALUATION_NO_SHOW':'NO-SHOW EM AVALIAÇÃO'
      }
      if(o[status])return o[status];
      return status;
    }

    static statusMarketing(status:any){
      const o:any = {
        'SENT':'ENVIADA',
        'CANCELED':'ENVIO CANCELADO',
        'SCHEDULED':'AGENDADA'
      }
      if(o[status])return o[status];
      return status;
    }

    static timestampDeISO(dataIso:any) {
      // Cria o objeto Date a partir da string ISO
      const data = new Date(dataIso);
    
      // Verifica se a data é válida
      if (isNaN(data.getTime())) {
        return 'Data inválida';
      }
    
      // Função auxiliar para adicionar zero à esquerda se necessário
      const pad = (numero:any) => numero < 10 ? `0${numero}` : numero;
    
      // Extração dos componentes da data
      const dia = pad(data.getDate());
      const mes = pad(data.getMonth() + 1); // Mês começa em zero!
      const ano = data.getFullYear();
      const horas = pad(data.getHours());
      const minutos = pad(data.getMinutes());
      const segundos = pad(data.getSeconds());
    
      // Retorno da data formatada
      return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
    }

    static diferencaEmDias(dataISO:any) {
      // Converte a data ISO para um objeto Date
      const dataFutura:any = new Date(dataISO);
      
      // Obtém a data de hoje e zera a hora para comparar apenas a data
      const dataHoje:any = new Date();
      dataHoje.setHours(0, 0, 0, 0);
      
      // Calcula a diferença em milissegundos
      const diferencaEmMilissegundos = dataFutura - dataHoje;
      
      // Converte a diferença de milissegundos para dias
      const diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
      
      return diferencaEmDias;
    }

    static  hoje() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda se necessário
      const day = String(today.getDate()).padStart(2, '0'); // Adiciona zero à esquerda se necessário
  
      return `${year}-${month}-${day}`;
    }

  
    





      static removerAcentos(s:any) {
        if (!s) return '';
        return s.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      }

      static CLONE(a:any){
        return JSON.parse(JSON.stringify(a));
      }

      static DATA_SQL_DE_DATA_FORMATADA(d:any){
        if((d+'').length==8){
            return `${d[4]+d[5]+d[6]+d[7]}-${d[2]+d[3]}-${d[0]+d[1]}`;
        }
        if((d+'').includes('/')){
            const ped = (d+'').split('/');
            return `${ped[2]}-${ped[1]}-${ped[0]}`;
        }
        return d;
      }

}
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IFormStateManager } from 'main/common/form-state-manager/IFormStateManager'
import { SalePaymentDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SalePaymentDataFormModel'
import { ModalCreateChargeCP } from 'main/modules/sale/components/modal-create-charge/ModalCreateChargeCP'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TableSaleCharges } from 'main/modules/sale/components/table-sale-charges/TableSaleCharges'
import { ISaleCharge } from 'main/modules/sale/components/table-sale-charges/inner/ISaleCharge'
import { MaskUtils } from 'main/common/utils/MaskUtils'
import { AlertCP } from 'main/common/components/alert/AlertCP'
import * as _ from 'lodash'
import { MoneyUtils } from 'main/common/utils/MoneyUtils'
import { TextCP } from 'main/common/components/text/TextCP'
import { ConditionalRenderCP } from 'main/common/components/conditional-render/ConditionalRenderCP'
import { CreateSaleUtils } from 'main/modules/sale/components/wizard-create-sale/inner/CreateSaleUtils'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'

interface ISalePaymentStepICPProps {
    formStateManager: IFormStateManager<SalePaymentDataFormModel>
    canGoNext: (isValid: boolean) => void
    opportunity?: IOpportunityResponseDTO
    saleDate?: Date
    isEditingSaleCharges: boolean
}

/**
 * Etapa de pagamento da Venda.
 */
export function SalePaymentStepICP(props: ISalePaymentStepICPProps): JSX.Element {

    const [isChargeModalVisible, setIsChargeModalVisible] = useState<boolean>(false)
    const [sumChargesValue, setSumChargesValue] = useState<number>(0)

    const [chargeToEdit, setChargeToEdit] = useState<ISaleCharge>()
    const [chargeToEditTableIndex, setChargeToEditTableIndex] = useState<number>()
    
    useEffect(init, [])

    /**
     * Inicializa.
     */
    function init(): void {
        if(!props.formStateManager.getFieldValue('valorJuro'))props.formStateManager.changeFieldValue('valorJuro',0);
        if(props.opportunity){
            console.log(">>",props.formStateManager.getFormValues());
            let totalChargeValue = 0
            if(props.formStateManager.getFieldValue('saleCharges')){
                let newCharges = [...props.formStateManager.getFieldValue('saleCharges')];
                newCharges.forEach((charge) => {
                    totalChargeValue += _.sumBy(charge.installments, 'value')
                });
                console.log("VALOR CHEGADO ", totalChargeValue);
                props.formStateManager.changeFieldValue('valorJuro',totalChargeValue-parseFloat(props.formStateManager.getFieldValue('totalValue')));
            }
        }
        updateChargeSum(props.formStateManager.getFieldValue('saleCharges') ?? [])
    }

    /**
     * Ao salvar uma forma de cobranca.
     */
    function onSaveChargeInstallments(saleCharge: ISaleCharge): void {

        const newCharges: ISaleCharge[] = props.formStateManager.getFieldValue('saleCharges') ? [...props.formStateManager.getFieldValue('saleCharges')] : []

        // Se tiver editando, atualiza a tabela
        if (!!chargeToEdit && chargeToEditTableIndex !== undefined)
            newCharges[chargeToEditTableIndex] = saleCharge
        else
            newCharges.push(saleCharge)

        props.formStateManager.changeFieldValue('saleCharges', newCharges)
        

        setChargeToEdit(undefined)
        setChargeToEditTableIndex(undefined)
        setIsChargeModalVisible(false)

        let totalChargeValue = 0
        newCharges.forEach((charge) => {
            totalChargeValue += _.sumBy(charge.installments, 'value')
        })
        const totalChargeFormatted = Number(Number(totalChargeValue).toFixed(2))
       
        const desconto:any = MoneyUtils.convertToFloat(props.formStateManager.getFieldValue('discountValue')) || 0;
        const valorOriginal = props.formStateManager.getFieldValue('originalTotalValue');
        let vj = parseFloat((valorOriginal-totalChargeFormatted).toFixed(2));
        vj = parseFloat(Math.abs(vj - desconto).toFixed(2));
        props.formStateManager.changeFieldValue('valorJuro',vj?vj:0);
        updateChargeSum(newCharges)
    }

    /**
     * Evento ao deletar uma cobranca.
     */
    function onDeleteSaleCharge(index: number): void {

        const newCharges = [...props.formStateManager.getFieldValue('saleCharges')]

        // Verifica se possui parcelas pagas em caso de edicao
        // Se tiver parcelas pagas, apaga apenas as parcelas nao pagas, caso contrario apaga a cobranca inteira
        if (CreateSaleUtils.hasPaidInstallments(newCharges[index].installments))
            newCharges[index].installments = _.filter(newCharges[index].installments, { isPaid: true })
        else
            newCharges.splice(index, 1)

        props.formStateManager.changeFieldValue('saleCharges', newCharges)
        updateChargeSum(newCharges)
    }

    /**
     * Atualiza a soma das parcelas.
     */
    function updateChargeSum(newCharges: ISaleCharge[]): void {

        let totalChargeValue = 0
        newCharges.forEach((charge) => {
            totalChargeValue += _.sumBy(charge.installments, 'value')
        })
        const totalChargeFormatted = Number(Number(totalChargeValue).toFixed(2))
        setSumChargesValue(totalChargeFormatted)
       
        props.canGoNext(CreateSaleUtils.isInstallmentAndTotalValueValids(totalChargeFormatted, props.formStateManager.getFieldValue('totalValue'),props.formStateManager))
    }

    return (
        <>
            <WrapperSCP>

                <TableSaleCharges
                    charges={props.formStateManager.getFieldValue('saleCharges')}
                    onDelete={onDeleteSaleCharge}
                />

                <FlexCP justifyContent={'center'} marginTop={30} marginBottom={20}>
                    <ButtonCP
                        onClick={() => {
                            setIsChargeModalVisible(true)
                            setChargeToEdit(undefined)
                        }}
                        icon={'plus'}
                        type={'ghost'}
                        disabled={CreateSaleUtils.isInstallmentAndTotalValueValids(sumChargesValue, props.formStateManager.getFieldValue('totalValue'),props.formStateManager)}
                        tooltip={CreateSaleUtils.isInstallmentAndTotalValueValids(sumChargesValue, props.formStateManager.getFieldValue('totalValue'),props.formStateManager) ? 'Você já selecionou formas de pagamento suficientes para o valor total da venda' : undefined}
                    >
                        Adicionar Forma de Pagamento
                    </ButtonCP>
                </FlexCP>

                <AlertCP
                    message={`Existe uma diferença de valores das parcelas e do total da venda: ${MaskUtils.applyMoneyMask(props.formStateManager.getFieldValue('totalValue') - sumChargesValue)}`}
                    type={'error'}
                    show={!CreateSaleUtils.isInstallmentAndTotalValueValids(sumChargesValue, props.formStateManager.getFieldValue('totalValue'),props.formStateManager)}
                    marginTop={15}
                    marginBottom={30}
                />

                <ConditionalRenderCP shouldRender={!props.isEditingSaleCharges}>
                    <FlexCP justifyContent={'flex-end'}>
                        <div style={{ textAlign: 'right' }}>
                            <TextCP text={`Total: ${MaskUtils.applyMoneyMask(props.formStateManager.getFieldValue('originalTotalValue'))}`}/>
                            <TextCP text={`Valor Juros: ${MaskUtils.applyMoneyMask(props.formStateManager.getFieldValue('valorJuro'))}`}/>
                            <TextCP text={`Desconto: ${MaskUtils.applyMoneyMask(MoneyUtils.convertToFloat(props.formStateManager.getFieldValue('discountValue')))}`}/>
                            <TextCP
                                strong={true}
                                size={'large'}
                                text={`Total líquido: ${MaskUtils.applyMoneyMask(props.formStateManager.getFieldValue('totalValue')+props.formStateManager.getFieldValue('valorJuro'))}`}
                            />
                        </div>
                    </FlexCP>
                </ConditionalRenderCP>
            </WrapperSCP>

            <ModalCreateChargeCP
                visible={isChargeModalVisible}
                saleDate={props.saleDate ?? new Date()}
                totalRemainingValue={(props.formStateManager.getFieldValue('totalValue') - sumChargesValue)}
                stateVenda={props.formStateManager}
                onSave={onSaveChargeInstallments}
                onCancel={() => setIsChargeModalVisible(false)}
                charge={chargeToEdit}
                isEditingSaleCharges={props.isEditingSaleCharges}
            />
        </>
    )
}

const WrapperSCP = styled.div`
`

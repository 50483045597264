import { WizardCP } from 'main/common/components/wizard/WizardCP'
import { SalePaymentStepICP } from 'main/modules/sale/components/wizard-create-sale/inner/steps/SalePaymentStepICP'
import { SaleSummaryStepCP } from 'main/modules/sale/components/wizard-create-sale/inner/steps/SaleSummaryStepCP'
import React, { useEffect, useState } from 'react'
import { IOpportunityResponseDTO } from 'main/modules/sales-funnel/services/opportunity/dtos/reponse/IOpportunityResponseDTO'
import { SaleBasicDataFormICP } from 'main/modules/sale/components/wizard-create-sale/inner/steps/SaleBasicDataFormICP'
import { SaleProductStepICP } from 'main/modules/sale/components/wizard-create-sale/inner/steps/SaleProductStepICP'
import { SaleBasicDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SaleBasicDataFormModel'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { SaleProductDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SaleProductDataFormModel'
import { SalePaymentDataFormModel } from 'main/modules/sale/components/wizard-create-sale/inner/form-models/SalePaymentDataFormModel'
import { CreateSaleUtils } from 'main/modules/sale/components/wizard-create-sale/inner/CreateSaleUtils'
import { ModalCreateSaleConfirmCP } from 'main/modules/sale/components/modal-create-sale-confirm/ModalCreateSaleConfirmCP'
import { SystemUtils } from 'main/common/utils/SystemUtils'
import { CreateSaleQuoteUtils } from 'main/modules/sale/components/wizard-create-sale/inner/CreateSaleQuoteUtils'
import { SaleQuoteRequests } from 'main/modules/sale/services/sale-quote/SaleQuoteRequests'
import { SaleTypeEnum, SaleTypeLabelEnum } from 'main/modules/sale/enums/SaleTypeEnum'
import { ISaleQuoteResponseDTO } from 'main/modules/sale/services/sale-quote/dtos/response/ISaleQuoteResponseDTO'
import { SaleRequests } from 'submodules/neritclin-sdk/services/sale/sale/SaleRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface IWizardCreateSaleCPProps {
    type: SaleTypeEnum
    opportunity?: IOpportunityResponseDTO
    customer?: INameAndCodeResponseDTO
    saleQuote?: ISaleQuoteResponseDTO
    closeDrawer?: () => void
    onSave?: (saleCode: number) => void
    onSelectPayer: (personCode: number) => void
}

/**
 * Wizard para realizar uma venda.
 */
export function WizardCreateSaleCP(props: IWizardCreateSaleCPProps): JSX.Element {

    const [isPreSaleModalVisible, setIsPreSaleModalVisible] = useState<boolean>(false)

    const [basicDataFormValidator, setBasicDataFormValidator] = useState<SaleBasicDataFormModel>(new SaleBasicDataFormModel())
    const basicDataFormStateManager = useFormStateManager<SaleBasicDataFormModel>(basicDataFormValidator)

    const [productDataFormValidator, setProductDataFormValidator] = useState<SaleProductDataFormModel>(new SaleProductDataFormModel())
    const productDataFormStateManager = useFormStateManager<SaleProductDataFormModel>(productDataFormValidator)

    const [paymentDataFormValidator, setPaymentDataFormValidator] = useState<SalePaymentDataFormModel>(new SalePaymentDataFormModel())
    const paymentDataFormStateManager = useFormStateManager<SalePaymentDataFormModel>(paymentDataFormValidator)

    const [enableProductsData, setEnableProductsData] = useState<boolean>(false)
    const [enablePaymentMethod, setEnablePaymentMethod] = useState<boolean>(false)

    const createSaleRequest = useRequest<number>()
    useEffect(onCreateSaleRequestChange, [createSaleRequest.isAwaiting])

    useEffect(init, [props.opportunity])

    /**
     * Inicializa
     */
    function init(): void {

        if (!props.opportunity?.personCustomer && !props.customer) {
            NotificationHelper.error('Ops!', 'O cliente não foi definido na oportunidade')
            props.closeDrawer?.()
            return
        }

        // Carrega os dados do orçamento
        if (!!props.saleQuote) {
            setBasicDataFormValidator(CreateSaleQuoteUtils.mountBasicFormModel(props.saleQuote.saleData.saleBasicData))
            setProductDataFormValidator(CreateSaleQuoteUtils.mountProductFormModel(props.saleQuote.saleData.saleProductData))
            setPaymentDataFormValidator(CreateSaleQuoteUtils.mountPaymentFormModel(props.saleQuote.saleData.salePaymentData))
            return
        }

        setBasicDataFormValidator(CreateSaleUtils.mountFormModel(props.opportunity, props.customer))
    }

    /**
     * Valida etapa de dados basicos da venda
     */
    async function validateBasicDataStep(): Promise<boolean> {

        basicDataFormStateManager.setConsiderAllErrors(true)
        const formValues = basicDataFormStateManager.getFormValues()
        const isValid = await basicDataFormStateManager.validate()

        if (!isValid || !formValues)
            return false

        if (formValues.source?.hasPersonLink && !formValues.sourcePersonLinkCode) {
            NotificationHelper.error('Ops!', 'Favor selecionar a pessoa indicada')
            return false
        }

        return true
    }

    /**
     * Faz a venda OU o orcamento.
     */
    function doSale(): void {

        // Retira o voucher se ele tiver sido des-selecionado
        if (!paymentDataFormStateManager.getFieldValue('voucherCode') && !!paymentDataFormStateManager.getFieldValue('voucher'))
            paymentDataFormStateManager.changeFieldValue('voucher', undefined)

        switch (props.type) {
            // VENDA
            case SaleTypeEnum.SALE:
                const createSaleRequestDto = CreateSaleUtils.prepareDataToSell(basicDataFormStateManager, productDataFormStateManager, paymentDataFormStateManager, props.opportunity?.code)
                if (!createSaleRequestDto)
                    return

                createSaleRequest.runRequest(SaleRequests.createSale(createSaleRequestDto))
                break

            // ORCAMENTO
            case SaleTypeEnum.SALE_QUOTE:
                const createSaleQuoteRequestDto = CreateSaleQuoteUtils.mountSaleQuoteDto(basicDataFormStateManager, productDataFormStateManager, paymentDataFormStateManager, props.opportunity?.code)
                if (!createSaleQuoteRequestDto)
                    return

                createSaleRequest.runRequest(SaleQuoteRequests.create(createSaleQuoteRequestDto))
                return

            default:
                break
        }
    }

    /**
     * Retorno do registro da venda
     */
    function onCreateSaleRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(createSaleRequest, `Erro ao salvar ${SaleTypeLabelEnum[props.type]}`))
            return

        NotificationHelper.success('Pronto!', `Sucesso ao salvar ${SaleTypeLabelEnum[props.type]}`)
        setIsPreSaleModalVisible(false)

        if (props.onSave)
            props.onSave(createSaleRequest.responseData!)
    }

    /**
     * Valida algumas algumas informacoes antes de gerar a venda, pois caso seja necessario sera aberto um modal para solicitar as informacoes
     */
    function validateBeforeSale(): void {

        // Se tiver ao menos uma fatura paga no cartao de credito devemos saber se sera paga na maquininha ou via link
        if (!SystemUtils.isEmpty(CreateSaleUtils.getPhysicalCharges(paymentDataFormStateManager)) && props.type === SaleTypeEnum.SALE) {
            setIsPreSaleModalVisible(true)
            return
        }

        // Caso nao tenha cartao, gera a venda
        doSale()
    }

    return (
        <>
            <WizardCP
                action={validateBeforeSale}
                steps={
                    CreateSaleUtils.getWizardSteps(
                        <SaleBasicDataFormICP
                            formStateManager={basicDataFormStateManager}
                            onSelectPayer={props.onSelectPayer}
                        />,
                        <SaleProductStepICP
                            formStateManager={productDataFormStateManager}
                            paymentFormStateManager={paymentDataFormStateManager}
                            canGoNext={setEnableProductsData}
                        />,
                        <SalePaymentStepICP
                            saleDate={basicDataFormStateManager.getFieldValue('saleDate')}
                            formStateManager={paymentDataFormStateManager}
                            canGoNext={setEnablePaymentMethod}
                            isEditingSaleCharges={false}
                            opportunity={props.opportunity}
                        />,
                        <SaleSummaryStepCP
                            productDataFormStateManager={productDataFormStateManager}
                            paymentDataFormStateManager={paymentDataFormStateManager}
                        />,
                        validateBasicDataStep,
                        (): boolean => enableProductsData,
                        (): boolean => enablePaymentMethod
                    )
                }
                loading={createSaleRequest.isAwaiting}
            />

            <ModalCreateSaleConfirmCP
                paymentDataFormStateManager={paymentDataFormStateManager}
                visible={isPreSaleModalVisible}
                onSave={doSale}
                onCancel={() => setIsPreSaleModalVisible(false)}
                loading={createSaleRequest.isAwaiting}
            />
        </>
    )
}


import { AppStateUtils } from "main/common/utils/AppStateUtils"
import React, { useEffect, useState } from "react";
import axios from 'axios'
import { notification } from 'antd';
import { Button } from 'antd'
import { DrawerCP } from "main/common/components/drawer/DrawerCP";
import { UtilGeral } from "main/modules/people/utils/UtilGeral";
import styled from "styled-components";
import { PermissionEnum } from "submodules/neritclin-sdk/services/user/enums/PermissionEnum";


export function Boleto(): JSX.Element {
    const [boleto,setBoleto] = useState(null);
    const [dados,setDados] = useState(null);
    const [paraVencer,setParaVencer] = useState<any>(null);
    const local = AppStateUtils.getCurrentFranchise()?.fantasyName;
    const eAdministrador = !!AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_ADMIN);
    useEffect(() => {
        const hj:any = localStorage.getItem('Boleto');
        if(hj==UtilGeral.hoje())return;
        const slug = AppStateUtils.getDomainSlug();
        const franchise = AppStateUtils.getCurrentFranchise();
        const dt = {schema:slug,id_unidade:franchise?.code};
        axios.post('https://financeiro.lumiacrm.com.br/api/buscarCobrancasAbertoPorUnidade',dt).then(response => {
            const dados = response.data;
            console.log("Dados do boleto",dados);
            if(dados.length > 0){
                if(!dados[0]['qrcode'])return<></>;// somente se for da nova modalidade de boletos.
                setBoleto(dados[0].pdf);
                setDados(dados[0]);
               setParaVencer(parseInt(UtilGeral.diferencaEmDias(dados[0]['expire_at'])+''));
            }else{
                setParaVencer(null);
                setDados(null);
                setBoleto(null);
            }
        }).catch(error => {
            console.log("BOLETO",error);
            setBoleto(null);
        });
    },[]);
    if(!boleto)return <></> ;
    const caminho: string = boleto+'';
    const abrirBoleto = () =>{
        window.open(caminho,'_blank');
    }

    async function checarSeEstaPago(){
        //@ts-ignore
        const dt = {charge_id:dados['charge_id']};
        axios.post('https://financeiro.lumiacrm.com.br/api/checarBoletoIndividual',dt).then(response => {
            const dados = response.data;
            console.log("Dados do boleto",dados);
            if(dados=='paid'){
                setDados(null);
                notification.open({
                    message: 'Bacana!',
                    description: (
                    <div>
                        O pagamento foi registrado com sucesso!
                    </div>
                    ),
                    duration: 3,
                    });
            }else{
                notification.open({
                    message: 'Ops',
                    description: (
                    <div>
                        O pagamento ainda não foi computado em nosso sistema. :(
                    </div>
                    ),
                    duration: 3,
                    });
            }
        }).catch(error => {
            console.log("BOLETO",error);
            setBoleto(null);
        });
    }

    function copiarParaAreaDeTransferencia(texto:any) {
        // Cria um elemento de texto temporário
        const elementoTemporario = document.createElement('textarea');
        elementoTemporario.value = texto;
        document.body.appendChild(elementoTemporario);
        
        // Seleciona o conteúdo do elemento temporário
        elementoTemporario.select();
        elementoTemporario.setSelectionRange(0, 99999); // Para dispositivos móveis
        
        // Executa o comando de cópia
        document.execCommand('copy');
        
        // Remove o elemento temporário
        document.body.removeChild(elementoTemporario);
        
            notification.open({
            message: 'Copiado',
            description: (
            <div>
                Código PIX copiado para área de transferência!
            </div>
            ),
            duration: 3,
            });
      }

      function BotaoBoleto(){
        if(paraVencer!=null && paraVencer>=0) return <Button type="primary" onClick={abrirBoleto}>Gerar Boleto</Button>;
        return <></>
      }

      function fechouDialogo(){
        console.log("BOLETO fechou dialogo");
        setDados(null);
        localStorage.setItem('Boleto',UtilGeral.hoje());
        return;
        if(paraVencer!=null && paraVencer>=0){
            setDados(null);
            localStorage.setItem('Boleto',UtilGeral.hoje());
        }

      }
      


    notification.config({
        placement: 'bottomRight',
      });
    
    // notification.open({
    //     message: 'Boleto em aberto',
    //     description: (
    //       <div>
    //         <b>Você possui um boleto em aberto.</b>
    //         <div style={{'textAlign':'right','marginTop':'12px'}}>
    //             <Button type="primary" onClick={abrirBoleto}>Gerar Boleto</Button>
    //         </div>
    //       </div>
    //     ),
    //     duration: 8,
    //   });

    // return<></>;

    //p closable={paraVencer!=null && paraVencer>=0}
    if(!eAdministrador)return <></>;// so exibe caso seja administrador
    return (
        <>
        <DrawerCP closable={true} onClose={fechouDialogo} title={'Controle de Mensalidade'} visible={!!dados} 
            footer={BotaoBoleto()}
        >

            <Tit>
                <div>
                    {local}
                    <Vencimento>
                        {/* @ts-ignore */}
                        {paraVencer!=null && paraVencer>=0?paraVencer+' dias para vencer':'VENCIDO'+paraVencer}
                    </Vencimento>
                </div>
                {/* @ts-ignore */}
                Vencimento da Mensalidade {dados!=null?UtilGeral.formatarDataISO(dados['expire_at']):''}
            </Tit>
            <AreaQr>
            {/* @ts-ignore */}
                <img src={dados && dados.qrcode?dados.qrcode:''} />
                <Button type="default" onClick={()=>{
                    //@ts-ignore
                    copiarParaAreaDeTransferencia(dados['copia_cola'])
                }}>Copiar código PIX (Copia e Cola)</Button>
            </AreaQr>
            <Tit>
                QRCode para pagamento via PIX
            </Tit>
            <EspacoBotoes>
                <Button type="primary" onClick={checarSeEstaPago}>Efetuei o Pagamento Por PIX</Button>
                
            </EspacoBotoes>
            
        </DrawerCP>
        </>
    )

}


const AreaQr = styled.div`
    box-shadow: rgba(0,0,0,0.4) 0px 2px 4px,rgba(0,0,0,0.3) 0px 7px 13px -3px,rgba(0,0,0,0.2) 0px -3px 0px inset;
    padding: 16px;
    border-radius: 11px;
    margin: 20px;
    display:flex;
    flex-direction:column;
    gap:10px;
`

const Tit = styled.div`
    font-weight: 686;
    text-align: center;
    font-size: 20px;
    border-bottom: solid 9px;
`

const EspacoBotoes = styled.div`
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-top: 18px;
`

const Vencimento = styled.div`
    font-size: 14px;
    font-weight: 654;
    line-height: 30px;
    color: red;
    border: solid;
`
import { ColumnProps } from 'antd/lib/table'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { TextCP } from 'main/common/components/text/TextCP'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { TagSessionStatusCP } from 'main/modules/scheduler/components/tag-session-status/TagSessionStatusCP'
import { SessionStatusEnum } from 'submodules/neritclin-sdk/services/schedule/enums/SessionStatusEnum'
import moment from 'moment'
import React from 'react'
import { StringUtils } from 'main/common/utils/StringUtils'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { ProgressCP } from 'main/common/components/progress/ProgressCP'
import { MathUtils } from 'main/common/utils/MathUtils'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { SessionResponseDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/responses/SessionResponseDTO'
import styled from 'styled-components'

/**
 * UTILITARIOS do componente de listagem de sessoes de um appointment
 */
export const SessionsTableUtils = {

    getColumnsConfig(
        onConcludeSession: (session: SessionResponseDTO) => void,
        onHistorico:(session: SessionResponseDTO)=>void,
        carregando:boolean,
        showTreatment: boolean,
        showDate: boolean,
        showUserResponsible: boolean,
    ): Array<ColumnProps<SessionResponseDTO>> {

        const columns: Array<ColumnProps<SessionResponseDTO>> = []
        columns.push({
            key: 'id',
            align: 'left',
            render: (text, session) => <TextCP size='small' text={session.code}/>
        })
        columns.push({
            key: 'status',
            align: 'center',
            render: (text, session) => <TagSessionStatusCP status={session.status}/>,
        })

        if (showTreatment) {
            columns.push({
                title: 'Serviço',
                key: 'title',
                render: (text, session) => <TextCP text={session.treatment.name}/>
            })
        }

        columns.push({
            key: 'prog',
            render: (text, session) => (
                <ProgressCP
                    width={50}
                    percent={MathUtils.getPercentage(session.sessionNumber, session.totalSessions)}
                    tooltip={`${session.sessionNumber} de ${session.totalSessions} sessões`}
                    showInfo={false}
                />
            )
        })

        columns.push({
            title: 'Data / Horário',
            key: 'startTime',
            render: (text, session) => (
                session.schedule
                    ?
                    <>
                        <TextCP
                            wrap={true}
                            text={`${DateUtils.getFormatted(session.schedule.beginDate, (showDate ? DateFormatEnum.BR_WITH_TIME_H_M : DateFormatEnum.TIME_H_M))} às ${moment(session.schedule.endDate).format(DateFormatEnum.TIME_H_M)} (${session.sessionDuration!}m)`}
                        />
                        <HelpCP text={`Unidade: ${session.schedule.franchise.name}`} type={'text'}/>
                    </>
                    :
                    <TextCP text={'Não agendada'}/>
            )
        })

        if (showUserResponsible) {
            columns.push({
                key: 'user',
                title: 'Responsável',
                render: (text, session) => {
                    if (!session.schedule)
                        return <></>
                    return <TextCP text={StringUtils.getFirstAndSecondName(session.schedule.scheduleUser.name)}/>
                },
            })
        }

        columns.push({
            title: '',
            key: 'actions',
            align: 'right',
            render: (text, session) => {
                if (session.status === SessionStatusEnum.AWAITING || session.status === SessionStatusEnum.NO_SHOW)
                    return <>
                        <ButtonCP 
                            type={'primary'}
                            icon={'history'}
                            tooltip={'Exibir histórico desta sessão'}
                            size={'small'}
                            loading={carregando}
                            onClick={()=> onHistorico(session)}
                        />
                    </>
                return (
                    <EmbrulhadorBotoes>
                        <ButtonCP 
                            type={'primary'}
                            icon={'history'}
                            tooltip={'Exibir histórico desta sessão'}
                            size={'small'}
                            loading={carregando}
                            onClick={()=> onHistorico(session)}
                        />
                        <ButtonCP
                            type={'primary'}
                            icon={'reconciliation'}
                            size={'small'}
                            onClick={() => onConcludeSession(session)}
                            tooltip={session.status === SessionStatusEnum.CONCLUDED ? 'Ver ficha de evolução' : 'Evoluir sessão'}
                        />
                    </EmbrulhadorBotoes>
                )
            }
        })

        return columns
    },

}

const EmbrulhadorBotoes = styled.div`
    display:flex;
    gap:5px;
`;